<template>
  <section class="container image1200text-block" :class="theme_color">
    <div class="content">
      <img preload v-if="value.image"
           :src="value.image.url | baseUrl"
           :width="value.image.width"
           :height="value.image.height"
           :alt="alt"
           loading="lazy"
      />
      <div v-html="text"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BlockImage1200',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    },
    text() {
      return this.value.text[this.$i18n.locale];
    },
    alt() {
      return this.value.image.image_translations?.find(x => x.language.slug === this.$i18n.locale)?.title || this.value.image.title;
    },
  },
}
</script>
