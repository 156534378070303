<template>
  <section class="container table-block-cols" :class="theme_color">
    <template v-if="getTable(value)">
      <div class="table-wrapper">

        <div class="table-row" v-for="(row, index_row) in getTable(value)"
             :class="{'header-table': value.first_row_header && index_row === 0}">

          <template v-for="(cell, index_cell) in row">
            <div v-if="value.table_cols[index_cell].col_type === 'link_product' && index_row !== 0"
                 :class="value.table_cols[index_cell].col_alignment">
              <nuxt-link :to="localePath('/product/' + cell)">{{ cell }}</nuxt-link>
            </div>
            <div v-else :class="value.table_cols[index_cell].col_alignment">{{cell}}</div>
          </template>

        </div>

      </div>
    </template>
  </section>
</template>

<script>

export default {
  name: 'BlockTableCols',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    }
  },
  methods: {
    getTable(obj) {
      return obj.table_data[this.$i18n.locale]
    }
  }
}
</script>
