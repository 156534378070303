<template>
  <section class="container table-block" :class="theme_color">
    <template v-if="getTable(value)">
      <div v-if="getTable(value).table_caption" class="h3" v-html="getTable(value).table_caption"></div>
      <hot-table :data="getTable(value).data"
                 :colHeaders="getTable(value).first_col_is_header"
                 :rowHeaders="getTable(value).first_row_is_table_header"
                 :width="'auto'"
                 :height="'auto'"
                 :readOnly="true"
                 :autoWrapRow="true"
                 :autoWrapCol="true"
                 licenseKey="non-commercial-and-evaluation">
      </hot-table>
    </template>
  </section>
</template>

<script>
import {HotTable} from '@handsontable/vue';

export default {
  name: 'BlockTable',
  props: ['value', 'theme'],
  components: {
    HotTable
  },
  computed: {
    theme_color() {
      return this.theme.value.theme
    }
  },
  methods: {
    getTable(obj) {
      return obj.table[this.$i18n.locale]
    }
  }
}
</script>
